<template>
  <div>
    <SiteNavigationBar></SiteNavigationBar>
    <main class="px-4">
      <section
        :style="{
          '--bg-image': `url(\'${cfUrl}/image?w=960&h=560&f=webp&fit=crop&image=${altHost}/images/home-background.jpg\')`,
          '--bg-image-lg': `url(\'${cfUrl}/image?w=1920&h=800&f=webp&fit=crop&image=${altHost}/images/home-background.jpg\')`,
          '--bg-image-portrait': `url(\'${cfUrl}/image?w=480&h=640&f=webp&fit=crop&image=${altHost}/images/home-background.jpg\')`,
          '--bg-image-portrait-lg': `url(\'${cfUrl}/image?w=960&h=1280&f=webp&fit=crop&image=${altHost}/images/home-background.jpg\')`
        }"
        class="!min-h-[calc(100svh-156px)] -mx-4 px-4 pt-[calc(60px+1rem)] md:pt-[calc(88px+1rem)] pb-8 w-[calc(100%+2rem)] flex flex-col items-center justify-start bg-cover bg-center landscape:bg-[image:var(--bg-image)] landscape:md:bg-[image:var(--bg-image-lg)] portrait:bg-[image:var(--bg-image-portrait)] portrait:md:bg-[image:var(--bg-image-portrait-lg)]"
      >
        <div class="flex-1 h-full max-h-[80px] lg:max-h-[134px]"></div>
        <h1
          class="mb-10 pt-2 md:pt-4 text-white font-bold text-xl sm:text-2xl md:max-w-[75%] drop-shadow text-left mx-auto leading-[1.25em] [text-wrap:balance] text-center"
        >
          {{ $t('home.hero.title') }}
        </h1>
        <form autocomplete="off" class="hidden" @submit.prevent.stop>
          <input
            autocomplete="false"
            name="hidden"
            type="text"
            class="hidden"
          />
          <input
            autocomplete="false"
            name="address"
            type="text"
            class="hidden"
          />
        </form>
        <form
          class="max-w-[1090px] flex flex-col gap-x-12 gap-y-4 justify-center items-center"
          autocomplete="off"
          @submit.prevent.stop="triggerSearch"
        >
          <input
            autocomplete="false"
            name="hidden"
            type="text"
            class="hidden"
          />
          <input
            autocomplete="false"
            name="address"
            type="text"
            class="hidden"
          />
          <AppToggleRadioButtons
            v-model="buyType"
            :options="buyTypes"
            class="variant-home tgi-set-width text-lg min-w-full min-[352px]:min-w-[320px] min-[392px]:min-w-[360px] max-w-[100%]"
            items-class="px-3 lg:px-6"
          ></AppToggleRadioButtons>
          <AppSelector
            v-model="propertyType"
            multiple
            :show-value="false"
            class="variant-home rounded text-lg h-[64px] w-full min-[352px]:w-[320px] min-[392px]:min-w-[360px] hide-icon"
            :class="{ 'text-font-bold': propertyType.length > 0 }"
            :title="propertyTypeButtonText"
            name="property-type"
            :options="
              propertyTypes.map(option => ({
                text: $t(option.text),
                value: option.value
              }))
            "
          >
            <Icon
              class="absolute right-4 top-0 w-8 h-full text-primary pointer-events-none"
              :path="mdiHomeOutline"
            />
          </AppSelector>
          <SiteSearchBar
            v-model="selectedLocation"
            class="variant-home rounded text-lg h-[64px] w-full min-[352px]:w-[320px] min-[392px]:w-[360px] min-[672px]:w-[640px] min-[752px]:w-[720px] max-w-[100%]"
            :placeholder="$t('home.location')"
            name="location"
            :search-new="buyType === 'NEW'"
            field-name="search-bar-home"
            use-location-from-store
            @update:search="searchValue = $event"
          >
            <template #button>
              <Icon
                class="text-primary w-8 h-full"
                :path="mdiMapMarkerOutline"
              />
            </template>
          </SiteSearchBar>
          <div
            class="max-[352px]:w-full min-[352px]:w-[320px] min-[392px]:min-w-[360px] grid grid-cols-2 gap-4"
          >
            <AppSelector
              v-model="minPrice"
              class="variant-home rounded text-lg h-[64px] w-full hide-icon price-range-selector"
              dropdown-class="!max-h-[340px]"
              :title="$t('search.priceFromShort')"
              name="min-price"
              deselectable
              :options="minPrices"
            >
            </AppSelector>
            <AppSelector
              v-model="maxPrice"
              class="variant-home rounded text-lg h-[64px] w-full hide-icon price-range-selector"
              dropdown-class="!max-h-[340px]"
              :title="$t('search.priceToShort')"
              name="max-price"
              deselectable
              :options="maxPrices"
            >
            </AppSelector>
          </div>
          <AppButton
            class="variant-primary rounded text-lg h-[64px] w-full min-[352px]:w-[320px] min-[392px]:min-w-[360px] shadow px-6"
            :to="selectedLocationLink || searchPath"
            @click="searchStore.saveQueryToStorage"
          >
            <span class="mr-auto">{{ $t('search.searchLabel') }}</span>
            <Icon class="w-8 h-8" :path="mdiMagnify" />
          </AppButton>
        </form>
        <div class="flex-1 h-full max-h-[140px]"></div>
      </section>
      <section
        id="services"
        class="flex flex-col gap-4 lg:gap-6 mb-2 lg:mb-4 pt-8 md:pt-12"
      >
        <h2
          class="max-w-[400px] text-neutral text-xl leading-7 font-bold uppercase text-center mx-auto mb-4 md:mb-8"
        >
          {{ $t('home.services.title') }} <span class="text-primary">360°</span>
        </h2>
        <HomeServices
          :items="[
            {
              icon: '/images/icons/properties.svg',
              title: $t('home.services.properties.title'),
              content: $t('home.services.properties.description'),
              link: localePath(
                `/${$t('routes.properties')}/${$t('paramsAliases.RES')}`
              )
            },
            {
              icon: '/images/icons/financing.svg',
              title: $t('home.services.financing.title'),
              content: $t('home.services.financing.description'),
              link: localePath($t('menu.financingLink'))
            },
            {
              icon: '/images/icons/rent-dollars.svg',
              title: $t('home.services.extra_income.title'),
              content: $t('home.services.extra_income.description'),
              link: localePath($t('menu.rentInDollarsLink'))
            },
            {
              icon: '/images/icons/administration.svg',
              title: $t('home.services.management.title'),
              content: $t('home.services.management.description'),
              link: localePath($t('menu.propertyManagementLink'))
            },
            {
              icon: '/images/icons/advisory.svg',
              title: $t('home.services.legal.title'),
              content: $t('home.services.legal.description'),
              link: localePath($t('menu.accountingLink'))
            }
          ]"
        />
      </section>
      <section
        id="properties-portfolio-section"
        class="flex flex-col pt-8 lg:pt-10 mb-8 lg:mb-10"
      >
        <h2
          class="max-w-[400px] text-neutral text-xl leading-7 font-bold uppercase text-center mx-auto mb-4"
        >
          {{ $t('home.apartmentsPortfolioDescription') }}
        </h2>
        <HomePortfolioPropertiesSlider type="apartments" class="mb-4" />
        <h2
          class="max-w-[400px] text-neutral text-xl leading-7 font-bold uppercase text-center mx-auto mb-4"
        >
          {{ $t('home.housesPortfolioDescription') }}
        </h2>
        <HomePortfolioPropertiesSlider type="houses" class="mb-6" />
        <h2
          class="max-w-[400px] text-neutral text-xl leading-7 font-bold uppercase text-center mx-auto mb-4"
        >
          {{ $t('home.orlandoPortfolioDescription') }}
        </h2>
        <HomePortfolioPropertiesSliderOrlando />
      </section>
      <section id="cities" class="flex flex-col gap-4 lg:gap-6 mb-8 lg:mb-12">
        <h2
          class="max-w-[400px] text-neutral text-xl leading-7 font-bold uppercase text-center mx-auto mb-4"
        >
          {{ $t('home.find_property_title') }}
        </h2>
        <p class="text-neutral text-center mx-auto mb-4">
          {{ $t('home.find_property_text') }}
        </p>
        <HomeCitiesSlider />
      </section>
      <section
        id="map-section"
        class="flex flex-col gap-4 lg:gap-6 mb-10 lg:mb-14"
      >
        <p
          class="max-w-[600px] text-neutral text-xl leading-7 font-bold uppercase text-center mx-auto mb-4"
        >
          {{ $t('home.clients.title') }}
        </p>
        <div
          class="grid grid-cols-1 md:grid-cols-2 items-center max-w-[1080px] mx-auto gap-12 lg:gap-16"
        >
          <img
            :src="`${cfUrl}/image?w=508&h=520&f=webp&fit=scale-down&image=${altHost}/images/map.png`"
            :srcset="`${cfUrl}/image?w=508&h=520&f=webp&fit=scale-down&image=${altHost}/images/map.png 1x, ${cfUrl}/image?w=1016&h=1040&f=webp&fit=scale-down&image=${altHost}/images/map.png 2x`"
            loading="lazy"
            alt="Mapa"
            class="w-full h-full object-cover object-center max-md:hidden"
            width="508"
            height="520"
          />
          <div class="flex flex-col gap-4">
            <p class="text-neutral text-lg leading-7 font-bold uppercase">
              {{ $t('home.clients.features.one.title') }}
            </p>
            <p class="text-base text-neutral mb-4">
              {{ $t('home.clients.features.one.description') }}
            </p>
            <p class="text-neutral text-lg leading-7 font-bold uppercase">
              {{ $t('home.clients.features.two.title') }}
            </p>
            <p class="text-base text-neutral mb-4">
              {{ $t('home.clients.features.two.description') }}
            </p>
            <p class="text-neutral text-lg leading-7 font-bold uppercase">
              {{ $t('home.clients.features.three.title') }}
            </p>
            <p class="text-base text-neutral">
              {{ $t('home.clients.features.three.description') }}
            </p>
          </div>
        </div>
      </section>
      <HomeEventsSlider class="flex flex-col mb-2 lg:mb-10 empty:mb-0" />
      <section
        id="testimonials"
        class="flex flex-col gap-4 lg:gap-6 mb-2 lg:mb-10"
      >
        <h2
          class="max-w-[400px] text-neutral text-xl leading-7 font-bold uppercase text-center mx-auto mb-4"
        >
          {{ $t('home.testimonialsTitle') }}
        </h2>
        <HomeTestimonialsSlider />
      </section>
      <HomeArticlesSlider v-if="locale !== 'us'" />
    </main>
    <Transition name="modal">
      <AppModal
        v-if="showPopupForm"
        class="modal-container fix-page"
        window-class="relative max-w-[calc(100vw-16px)] max-h-[calc(100svh-32px)] min-[412px]:max-w-[480px] w-full bg-white rounded-md md:rounded-lg shadow-lg py-4 px-8 overflow-y-auto"
        @click="closePopupForm"
      >
        <AppButton
          class="icon-button variant-invisible ml-auto h-8 w-8 !absolute right-2 top-2"
          :icon="mdiClose"
          :aria-label="$t('common.closePopup')"
          @click="closePopupForm"
        />
        <div class="font-bold text-lg md:text-xl mb-4">
          {{ $t('home.popupFormTitle') }}
        </div>
        <div class="text-base mb-4">
          {{ $t('home.popupFormMessage') }}
        </div>
        <HubspotForm
          class="w-full mb-4"
          element-id="home-popup-form"
          :form-id="{
            es: '5b909a22-7b6b-460a-8a53-df343e780120',
            en: 'c471e18b-4bee-4650-936e-7e2522b38718'
          }"
          @submitted="
            () => {
              showPopupForm = false;
              homePopupFormDone = 'true';
            }
          "
        />
      </AppModal>
    </Transition>
    <SiteFooter v-once :fix-locale="locale" />
  </div>
</template>

<script setup lang="ts">
import {
  mdiClose,
  mdiHomeOutline,
  mdiMagnify,
  mdiMapMarkerOutline
} from '@mdi/js';

import {
  buyPrices,
  propertyTypesOptions as propertyTypes,
  rentPrices,
  valueToParam
} from '@/helpers/search';
import { joinAnd } from '@/helpers/utils';
import { useSearchStore } from '@/store/search';

const AppModal = defineAsyncComponent(
  () => import('@/async-components/App/Modal.vue')
);
const AppSelector = defineAsyncComponent(
  () => import('@/async-components/App/Selector.vue')
);
const AppToggleRadioButtons = defineAsyncComponent(
  () => import('@/async-components/App/ToggleRadioButtons.vue')
);
const HomeArticlesSlider = defineAsyncComponent(
  () => import('@/async-components/Home/ArticlesSlider.vue')
);
const HomeCitiesSlider = defineAsyncComponent(
  () => import('@/async-components/Home/CitiesSlider.vue')
);
const HomeEventsSlider = defineAsyncComponent(
  () => import('@/async-components/Home/EventsSlider.vue')
);
const HomePortfolioPropertiesSlider = defineAsyncComponent(
  () => import('@/async-components/Home/PortfolioPropertiesSlider.vue')
);
const HomePortfolioPropertiesSliderOrlando = defineAsyncComponent(
  () => import('@/async-components/Home/PortfolioPropertiesSliderOrlando.vue')
);
const HomeServices = defineAsyncComponent(
  () => import('@/async-components/Home/Services.vue')
);
const HomeTestimonialsSlider = defineAsyncComponent(
  () => import('@/async-components/Home/TestimonialsSlider.vue')
);
const HubspotForm = defineAsyncComponent(
  () => import('@/async-components/HubspotForm.vue')
);
const SiteNavigationBar = defineAsyncComponent(
  () => import('@/async-components/Site/NavigationBar.vue')
);
const SiteSearchBar = defineAsyncComponent(
  () => import('@/async-components/Site/Search/Bar.vue')
);

const localePath = useLocalePath();

const { t, locale } = useI18n();

const {
  public: { cloudflareImageProcessing: cfUrl }
} = useRuntimeConfig();

const altHost = 'https://pfsrealty.com';

const searchStore = useSearchStore();

const homePopupFormDone = useCookie('home-popup-form-done');

const showPopupForm = ref(false);

const buyType = ref<'RES' | 'NEW' | 'RNT'>('RES');

const buyTypes = [
  { label: t('home.buy'), value: 'RES' },
  { label: t('home.new'), value: 'NEW' },
  { label: t('home.rent'), value: 'RNT' }
];

const propertyType = ref([]);

const { selectedLocation, selectedLocationLink } = useSelectableLocation(() => {
  let priceQuery = '';

  if (minPrice.value && maxPrice.value) {
    priceQuery = `${minPrice.value}-${maxPrice.value}`;
  } else if (minPrice.value) {
    priceQuery = `${minPrice.value}p`;
  } else if (maxPrice.value) {
    priceQuery = `${minPrices.value[0].value}-${maxPrice.value}`;
  }

  return {
    query: buyType.value === 'NEW' ? 'projects' : 'properties',
    propertyType:
      propertyType.value?.length === 1 ? propertyType.value[0] : null,
    buyType: buyType.value,
    priceQuery,
    beforeNavigate: searchStore.saveQueryToStorage
  };
});

const searchValue = ref('');

const minPrice = ref<number | null>(null);

const maxPrice = ref<number | null>(null);

const minPrices = computed(() => {
  return buyType.value === 'RNT' ? rentPrices : buyPrices;
});

const maxPrices = computed(() => {
  const prices = buyType.value === 'RNT' ? rentPrices : buyPrices;

  return prices.filter(
    price => !minPrice.value || price.value >= minPrice.value
  );
});

const propertyTypeButtonText = computed(() => {
  if (!propertyType.value) {
    return t('home.propertyType');
  }

  return joinAnd(
    propertyType.value.map(propertyType =>
      t(propertyTypes.find(option => option.value === propertyType)?.text)
    ),
    t
  );
});

watch(
  () => buyType.value,
  () => {
    if (
      minPrice.value &&
      !minPrices.value.find(v => v.value === minPrice.value)
    ) {
      minPrice.value = null;
    }

    if (
      maxPrice.value &&
      !maxPrices.value.find(v => v.value === maxPrice.value)
    ) {
      maxPrice.value = null;
    }
  }
);

const searchPath = computed(() => {
  const query: Record<string, string> = {};

  let searchProjects = false;

  if (buyType.value) {
    if (buyType.value === 'NEW') {
      searchProjects = true;
    }

    query.buyType = buyType.value;
  }

  if (propertyType.value?.length === 1) {
    query.propertyType = propertyType.value[0];
  }

  if (searchValue.value) {
    query.location = searchValue.value;
  }

  if (minPrice.value && maxPrice.value) {
    query.price = `${minPrice.value}-${maxPrice.value}`;
  } else if (minPrice.value) {
    query.price = `${minPrice.value}p`;
  } else if (maxPrice.value) {
    query.price = `${minPrices.value[0].value}-${maxPrice.value}`;
  }

  return localePath(
    `/${t(searchProjects ? 'routes.projects' : 'routes.properties')}/` +
      ['buyType', 'propertyType', 'neighborhood', 'location', 'price']
        .map(k => {
          // TODO: This code is repeated in composables/use-selectable-location.ts
          let v = query?.[k];
          if (k === 'buyType' && v === 'NEW') {
            return '';
          }
          if (!(k in query)) {
            if (k === 'buyType') {
              v = 'RES';
            } else {
              return '';
            }
          }
          if (k === 'buyType' || k === 'propertyType') {
            return valueToParam(v, true, t);
          } else {
            if (v) {
              return v + '-' + t(`paramsKeys.${k}`);
            }
            return '';
          }
        })
        .filter(Boolean)
        .join('/')
  );
});

function closePopupForm() {
  showPopupForm.value = false;
  homePopupFormDone.value = 'true';
}

function triggerSearch() {
  const path = searchPath.value;

  if (path) {
    console.log('[SEARCH] Navigating to', path);
    navigateTo(path);
  }
}

useSeoExtended({
  title: t('home.seo.title'),
  description: t('home.seo.description'),
  image: '/images/pfs-placeholder.svg'
});

const { link } = useCanonicalLink('/');

useSchemaOrg([
  ...useCommonSchemaOrg(link),
  defineBreadcrumb({
    '@id': link + '#breadcrumb',
    itemListElement: [
      {
        name: t('seo.home'),
        item: localePath('/')
      }
    ]
  })
]);

// onMounted(() => {
//   if (!homePopupFormDone.value && locale.value === 'mx') {
//     setTimeout(() => {
//       if (!isAuthenticated.value) {
//         showPopupForm.value = true;
//       }
//     }, 55 * 1000);
//   }
// });
</script>

<style lang="scss">
.tgi-set-width {
  label {
    @apply min-[356px]:max-md:min-w-[106.66px] md:min-w-[120px] #{!important};
  }
}

.price-range-selector {
  .selector-button {
    @apply max-[340px]:text-base;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .selector-buttonText {
    text-overflow: unset !important;
  }
}

.home-form {
  .hs-input:not([type='checkbox']):not([type='radio']),
  .hbspt-form .hs-input:not([type='checkbox']):not([type='radio']) {
    @apply bg-white/70 text-neutral;
  }

  .field {
    @apply pb-4 #{!important};
  }

  .hs-submit {
    @apply w-[50%] mx-auto;
  }

  .hs-button {
    @apply px-10;
  }
}

.text-font-bold {
  .selector-buttonText {
    @apply font-bold text-neutral;
  }
}
</style>
